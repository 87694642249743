import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";

import { Dimmer, ModalContainer } from "./styles";

interface ModalProps {
  open: boolean;
  videoUrl: string;
  onClose: () => void;
}

const Modal: FunctionComponent<ModalProps> = ({ open, onClose, videoUrl }) => {
  const { formatMessage } = useIntl();
  return open ? (
    <Dimmer>
      <ModalContainer>
        <iframe
          style={{ backgroundColor: "darkgray" }}
          width={560}
          height={315}
          src={videoUrl || ""}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="video"
        />
        <div className="closeButtonContainer" onClick={() => onClose()}>
          <div className="closeButton">
            {formatMessage({ id: "pages.solutions.close-video-label" })}
          </div>
        </div>
      </ModalContainer>
    </Dimmer>
  ) : null;
};

export default Modal;
