import styled from "@emotion/styled";

export const Rectangle = styled.div`
  position: absolute;
  right: 0;
  width: 80%;
  /* Grid System md-breakpoint */
  @media (max-width: 786px) {
    width: 100%;
  }
  height: 160px;
  background-color: ${(props: { theme?: any }) =>
    props.theme.colors.decunifyOrange};
`;

export const IntroContainer = styled.div`
  margin: 40px 0 62px;

  .largeOnly {
    /* md breakpoint  */
    @media (max-width: 768px) { 
      display: none;
    }
  }

  .smallOnly {
    /* md breakpoint  */
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }

  .leftContent {
    img {
      height: 397px;
      object-fit: cover;
    }
  }

  .rightContent {
    display: flex;
    flex-direction: column;
    background-color: ${(props: { theme?: any }) => props.theme.colors.white};
    padding: 36px 52px 0px 78px;

    /* Grid System md-breakpoint */
    @media (max-width: 786px) {
      text-align: center;
      padding: 27px 5px 0px;
    }

    .title {
      font-family: Roboto;
      font-size: 36px;
      line-height: normal;
      p {
        margin-bottom: 0px;
      }
    }
    .description {
      margin: 27px 0 80px;
      font-family: Roboto;
      font-size: 18px;
      line-height: 1.44;
      p {
        margin-bottom: 0px;
      }
    }

    .video-container {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      width: 475px;
      height: 55px;

      /* Grid System md-breakpoint */
      @media (max-width: 786px) {
        width: 100%;
      }
    }
  }

  .mobileImage {
    img {
      width: 100%;
      object-fit: cover;
    }
    margin-bottom: 40px;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  margin-bottom: 129px;
  display: flex;
  flex-wrap: wrap;

  /* Grid System md-breakpoint */
  @media (max-width: 786px) {
    justify-content: center;
    max-width: 300px;
    margin-right: auto;
    margin-left: auto;
  }

  flex: 1 0 202px;
`;

interface IButtons {
  theme?: any;
  negative?: boolean;
}

export const ButtonSolution = styled.div<IButtons>`
  width: 190px;
  min-width: 190px;
  height: 45px;
  border: 1px solid
    ${(props: { theme?: any; negative?: boolean }) =>
      props.theme.colors.decunifyOrange};
  background-color: ${(props: { theme?: any; negative?: boolean }) =>
    props.negative
      ? props.theme.colors.decunifyOrange
      : props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 2px;
  margin: 6px;

  span {
    text-decoration: none;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: 1.5px;
    text-align: center;
    color: ${(props: { theme?: any; negative?: boolean }) =>
      props.negative ? props.theme.colors.white : props.theme.colors.black};
    :hover {
      border-bottom: 3px solid #f38f1d;
      transition-timing-function: ease-in;
      transition: 0.1s;
    }
  }
`;

export const MainContainer = styled.div`
  padding-bottom: 200px;
`;

export const PlayContainer = styled.div`
  width: 68px;
  height: 52px;
  border-radius: 8px;
  border: solid 3px ${(props: { theme?: any }) => props.theme.colors.playGrey2};
  padding-top: 12px;
  padding-left: 25px;
  margin-right: 22px;

  /* Grid System md-breakpoint */
  @media (max-width: 786px) {
    margin: 0px auto 22px;
  }
`;

export const PlayButton = styled.div`
  width: 0px;
  height: 0px;
  border-left: 20px solid
    ${(props: { theme?: any }) => props.theme.colors.playGrey};
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
`;

export const Caption = styled.div`
  font-family: Roboto;
  font-size: 28px;
  font-weight: lighter;
  line-height: 1.07;

  p {
    margin-bottom: 0px;

    /* Grid System md-breakpoint */
    @media (max-width: 786px) {
      text-align: center;
      line-height: 1.4;
    }
  }
`;

export const FinalButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
