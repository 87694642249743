import styled from "@emotion/styled";

interface OrderProps {
  order?: number;
  type?: string;
}

export const ItemTitle = styled.div<OrderProps>`
  font-family: Roboto;
  font-size: 21px;
  font-weight: bold;
  line-height: normal;
  margin-bottom: 24px;
  color: ${(props: { theme?: any; order?: number }) =>
    props.theme.colors.decunifyOrange};
`;

export const ItemDescription = styled.div<OrderProps>`
  font-family: Roboto;
  font-size: 18px;
  line-height: 1.45;
`;

export const ItemRectangle = styled.div`
  background-color: ${(props: { theme?: any; order?: number }) =>
    props.theme.colors.decunifyOrange};
`;

export const ItemImages = styled.div`
  position: relative;

  img {
    position: relative;
    min-height: 296px;
    max-height:296px;
    object-fit: cover;
    width: 95%;
    z-index: 5;
  }
  ${ItemRectangle} {
    position: absolute;
    min-height: 200px;
    width: 65%;
    right: 0;
    top: 40%;
    z-index: 1;
  }
`;
