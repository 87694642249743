import React, { Component, Fragment } from "react";
import { Container, Row, Col, Hidden, Visible } from "react-grid-system";
import {
  ItemTitle,
  ItemDescription,
  ItemRectangle,
  ItemImages
} from "./styles";

interface ItemType {
  subtitle: string;
  description: {
    html: string;
  };
  image: {
    url: string;
    alt: string;
  };
  order: number;
}

interface IProps {
  page: string;
  items: ItemType[];
}

class SolutionBody extends Component<IProps, { sortedItems: ItemType[] }> {
  state: { sortedItems: ItemType[] } = {
    sortedItems: []
  };

  constructor(props: Readonly<IProps>) {
    super(props);
    this.state = {
      sortedItems: []
    };
  }

  componentWillMount() {
    this.setState({
      sortedItems: this.props.items.sort((a: any, b: any) => a.order - b.order)
    });
  }

  bodyItem(
    item: any,
    options: {
      col1xs?: number;
      col2xs?: number;
      col1xsoff?: number;
      col2xsoff?: number;
    }
  ) {
    return (
      <Row style={{ marginBottom: "142px" }}>
        <Col xs={options.col1xs} offset={{ xs: options.col1xsoff }}>
          <ItemTitle id={`${this.props.page}-${item.order}`}>
            {item.subtitle.toUpperCase()}
          </ItemTitle>
          <ItemDescription
            dangerouslySetInnerHTML={{ __html: item.description.html }}
          />
        </Col>
        <Col xs={options.col2xs} offset={{ xs: options.col2xsoff }}>
          <ItemImages>
            <ItemRectangle />
            <img src={item.image.url} alt={item.image.alt} />
          </ItemImages>
        </Col>
      </Row>
    );
  }

  mobileBodyItem(item: any) {
    return (
      <Fragment key={item.order}>
        <Row style={{ marginBottom: "30px" }}>
          <Col xs={12}>
            <ItemTitle id={`${this.props.page}-${item.order}`}>
              {item.subtitle.toUpperCase()}
            </ItemTitle>
            <ItemDescription
              dangerouslySetInnerHTML={{ __html: item.description.html }}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "100px" }}>
          <Col xs={12}>
            <ItemImages>
              <ItemRectangle />
              <img src={item.image.url} alt={item.image.alt} />
            </ItemImages>
          </Col>
        </Row>
      </Fragment>
    );
  }

  render() {
    const { page } = this.props;
    return (
      <Container>
        <Hidden xs sm>
          {page === "data-center" && (
            <>
              {/* salas técnicas */}
              {this.bodyItem(this.state.sortedItems[0], {
                col1xs: 5,
                col2xs: 5
              })}
              {/* servidores e storage */}
              {this.bodyItem(this.state.sortedItems[1], {
                col1xs: 7,
                col2xs: 5,
                col2xsoff: 1
              })}
              {/* virtualização */}
              {this.bodyItem(this.state.sortedItems[2], {
                col1xs: 6,
                col2xs: 4,
                col1xsoff: 2
              })}
            </>
          )}
          {page === "digital-infrastructure" && (
            <>
              {/* CABLAGEM ESTRUTURADA */}
              {this.bodyItem(this.state.sortedItems[0], {
                col1xs: 7,
                col2xs: 5
              })}
              {/* REDES WIRED E WIRELESS */}
              {this.bodyItem(this.state.sortedItems[1], {
                col1xs: 7,
                col2xs: 5
              })}
              {/* CIBERSEGURANÇA */}
              {this.bodyItem(this.state.sortedItems[2], {
                col1xs: 6,
                col2xs: 4,
                col1xsoff: 2
              })}
              {/* IOT */}
              {this.bodyItem(this.state.sortedItems[3], {
                col1xs: 7,
                col2xs: 5,
                col2xsoff: 1
              })}
              {/* SND — Software Defined Network */}
              {this.bodyItem(this.state.sortedItems[4], {
                col1xs: 7,
                col2xs: 5,
                col1xsoff: 5,
                col2xsoff: 7
              })}
              {/* gestão documental */}
              {this.bodyItem(this.state.sortedItems[5], {
                col1xs: 5,
                col2xs: 4
              })}
              {/* gestão documental */}
              {this.bodyItem(this.state.sortedItems[6], {
                col1xs: 5,
                col2xs: 4
              })}
            </>
          )}
          {page === "collaboration" && (
            <>
              {/* COMUNICAÇÕES UNIFICADAS */}
              {this.bodyItem(this.state.sortedItems[0], {
                col1xs: 5,
                col2xs: 5
              })}

              {/* VIDEOCONFERÊNCIA */}
              {this.bodyItem(this.state.sortedItems[1], {
                col1xs: 6,
                col2xs: 5
              })}

              {/* CONTACT CENTER */}
              {this.bodyItem(this.state.sortedItems[2], {
                col1xs: 6,
                col2xs: 4,
                col1xsoff: 2
              })}

              {/* PUBLIC SAFETY */}
              {this.bodyItem(this.state.sortedItems[3], {
                col1xs: 8,
                col2xs: 5
              })}
            </>
          )}
          {page === "audio-video" && (
            <>
              {/* AUDITÓRIOS */}
              {this.bodyItem(this.state.sortedItems[0], {
                col1xs: 5,
                col2xs: 5
              })}
              {/* SALAS DE REUNIÕES */}
              {this.bodyItem(this.state.sortedItems[1], {
                col1xs: 8,
                col2xs: 5
              })}
              {/* DOMÓTICA */}
              {this.bodyItem(this.state.sortedItems[2], {
                col1xs: 6,
                col2xs: 4,
                col1xsoff: 2
              })}
              {/* SEGURANÇA */}
              {this.bodyItem(this.state.sortedItems[3], {
                col1xs: 8,
                col2xs: 6
              })}
              {/* VIDEO ANALYTICS */}
              {this.bodyItem(this.state.sortedItems[4], {
                col1xs: 5,
                col2xs: 6,
                col1xsoff: 1
              })}
            </>
          )}
        </Hidden>
        <Visible xs sm>
          {this.state.sortedItems.map(item => {
            return this.mobileBodyItem(item);
          })}
        </Visible>
      </Container>
    );
  }
}

export default SolutionBody;
