import { Container, Row, Col } from "react-grid-system";
import React, { FunctionComponent, useState } from "react";
import { useIntl } from "react-intl";
import { graphql } from "gatsby";
import Link from "gatsby-link";
import { FadeSlideAnimationContainer } from "../../styles/animations";
import Modal from "../../components/common/MediaModal";
import Preview from "../../components/common/Preview";
import SolutionBody from "./SolutionBody";
import { Layout } from "../../components";

import {
  Rectangle,
  IntroContainer,
  PlayContainer,
  PlayButton,
  ButtonsContainer,
  ButtonSolution,
  Caption,
  FinalButtonContainer,
  MainContainer,
} from "./styles";

interface IProps {
  data: {
    prismicSolutionPage: {
      uid: string;
      data: {
        title: { text: string; html: string };
        intro: { html: string };
        caption: { html: string };
        video_url?: {
          url: string;
        };
        main_image: {
          url: string;
          alt: string;
        };
        body: Array<{
          items: Array<{
            order: number;
            subtitle: string;
            description: { html: string };
            image: {
              url: string;
              alt: string;
            };
          }>;
        }>;
        seo_title?: string;
        seo_description?: string;
        seo_image?: { alt: string; url: string };
        canonical_url?: {
          url: string;
        };
      };
    };
  };
}

const SolutionTemplate: FunctionComponent<IProps> = ({ data }) => {
  const { locale, formatMessage } = useIntl();
  const [videoOpen, setVideoOpen] = useState<boolean>(false);

  const scrollTo = (anchor: string) => {
    const element = document.querySelector(anchor);
    if (element) {
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementPosition =
        element.getBoundingClientRect().top - bodyRect - 144;
      window.scrollTo({
        top: elementPosition,
        behavior: "smooth",
      });
    }
  };

  const {
    prismicSolutionPage: {
      uid,
      data: {
        title: { text: titleText, html: titleHTML },
        intro: { html: intro },
        caption: { html: caption },
        main_image: { url: image, alt },
        body,
      },
    },
  } = data;
  const items = body[0].items;

  return (
    <Layout
      metaTitle={titleText || data.prismicSolutionPage.data.seo_title}
      metaDescription={
        data.prismicSolutionPage.data.seo_description ||
        formatMessage({
          id: "pages.solutions.meta-description",
        })
      }
      metaKeywords={
        data.prismicSolutionPage.data.seo_keywords ||
        formatMessage({
          id: "pages.solutions.meta-keywords",
        })
      }
      metaImage={data.prismicSolutionPage.data.seo_image}
      metaURL={data.prismicSolutionPage.data.canonical_url?.url}
    >
      <Rectangle />
      <Container>
        <Row>
          <Col xs={12}>
            <IntroContainer>
              <Row>
                <Col xs={4} className="largeOnly">
                  <div className="leftContent">
                    <img src={image} alt={alt} />
                  </div>
                </Col>
                <Col xs={12} md={8}>
                  <div className="rightContent">
                    <FadeSlideAnimationContainer duration={2}>
                      <div
                        className="title"
                        dangerouslySetInnerHTML={{ __html: titleHTML }}
                      />
                      <div
                        className="description"
                        dangerouslySetInnerHTML={{ __html: intro }}
                      />
                    </FadeSlideAnimationContainer>
                    {data.prismicSolutionPage.data.video_url?.url && (
                      <FadeSlideAnimationContainer duration={4}>
                        <div
                          onClick={() => setVideoOpen(true)}
                          className={"video-container largeOnly"}
                          style={{ cursor: "pointer" }}
                        >
                          <PlayContainer>
                            <PlayButton />
                          </PlayContainer>
                          <Caption
                            dangerouslySetInnerHTML={{ __html: caption }}
                          />
                        </div>
                      </FadeSlideAnimationContainer>
                    )}
                  </div>
                </Col>
                {data.prismicSolutionPage.data.video_url?.url && (
                  <>
                    <Col xs={12} className="smallOnly">
                      <div className="mobileImage">
                        <img src={image} alt={alt} />
                      </div>
                    </Col>
                    <Col xs={12} className="smallOnly">
                      <div
                        onClick={() => setVideoOpen(true)}
                        className="video-container"
                        style={{ cursor: "pointer" }}
                      >
                        <PlayContainer>
                          <PlayButton />
                        </PlayContainer>
                        <Caption
                          dangerouslySetInnerHTML={{ __html: caption }}
                        />
                      </div>
                    </Col>
                  </>
                )}
              </Row>
            </IntroContainer>
            <ButtonsContainer>
              {items.map(({ subtitle, order }, index) => (
                <a
                  key={index}
                  className="buttonLink"
                  onClick={() => scrollTo(`#${uid}-${order}`)}
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                >
                  <ButtonSolution key={subtitle}>
                    <span>{subtitle.toUpperCase()}</span>
                  </ButtonSolution>
                </a>
              ))}
              <Link
                className="buttonLink"
                to={`/${locale}/#solutions`}
                state={{ skipSolutionsAnimation: true }}
              >
                <ButtonSolution key="other" negative>
                  <span style={{ textTransform: "uppercase" }}>
                    {formatMessage({ id: "other-solutions" })}
                  </span>
                </ButtonSolution>
              </Link>
            </ButtonsContainer>
            <MainContainer>
              <SolutionBody items={items} page={uid} />
              <FinalButtonContainer>
                <Link
                  className="buttonLink"
                  to={`/${locale}/#solutions`}
                  state={{ skipSolutionsAnimation: true }}
                >
                  <ButtonSolution key="other" negative>
                    <span style={{ textTransform: "uppercase" }}>
                      {formatMessage({ id: "other-solutions" })}
                    </span>
                  </ButtonSolution>
                </Link>
              </FinalButtonContainer>
            </MainContainer>
          </Col>
        </Row>
      </Container>
      <Modal
        open={videoOpen}
        onClose={() => setVideoOpen(false)}
        videoUrl={data.prismicSolutionPage.data.video_url?.url || ""}
      />
    </Layout>
  );
};

export const query = graphql`
  query($uid: String!, $lang: String!) {
    prismicSolutionPage(uid: { eq: $uid }, lang: { eq: $lang }) {
      data {
        intro {
          html
        }
        main_image {
          url
          alt
        }
        title {
          text
          html
        }
        caption {
          html
        }
        video_url {
          url
        }
        body {
          ... on PrismicSolutionPageBodySolution {
            items {
              description {
                html
              }
              subtitle
              image {
                url
                alt
              }
              order
            }
          }
        }
        seo_title
        seo_description
        seo_keywords
        seo_image {
          alt
          url
        }
        canonical_url {
          url
        }
      }
      uid
    }
  }
`;

export default Preview(SolutionTemplate);
