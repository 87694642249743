import styled from "@emotion/styled";

export const Dimmer = styled.div`
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.71);
`;

export const ModalContainer = styled.div`
  .closeButtonContainer {
    cursor: pointer;

    .closeButton {
      width: 50%;
      min-width: 190px;
      height: 45px;

      margin: auto;
      padding: 0px 2px;
      background-color: white;

      display: flex;
      align-items: center;
      justify-content: center;

      border: 1px solid
        ${(props: { theme?: any }) => props.theme.colors.decunifyOrange};

      text-decoration: none;
      font-family: Roboto;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      letter-spacing: 1.5px;
      text-align: center;
      color: ${(props: { theme?: any; negative?: boolean }) =>
        props.negative ? props.theme.colors.white : props.theme.colors.black};

      :hover {
        border-bottom: 3px solid #f38f1d;
        transition-timing-function: ease-in;
        transition: 1s;
      }
    }
  }
`;
